import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && data.pwr.telemetry === "On" ? 'green' : 'white';


  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }


  let finaledge;

  //const edge1 = `M480 575.5L 510,575.5Q 520,575.5 520,565.5L 520,300Q 520,290 530,290L 2365.5,290Q 2375.5,290 2375.5,300L2375.5 571`;
  if (id === 'edge2'){
    const edge = `M${targetX} ${targetY}L${targetX+210} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge21'){
    const edge = `M${sourceX} ${sourceY}L${sourceX-160} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'edge9'){
    const edge = `M${sourceX} ${sourceY}L${sourceX-150} ${sourceY+50}`;
    finaledge = edge;
  }
  else if (id === 'edge10'){
    const edge = `M${sourceX} ${sourceY}L${sourceX+230} ${sourceY+50}`;
    finaledge = edge;
  }
  else if (id === 'edge11'){
    const edge = `M${sourceX} ${sourceY}L${sourceX+230} ${sourceY+50}`;
    finaledge = edge;
  }
  else if (id === 'edge12'){
    const edge = `M${sourceX} ${sourceY}L${sourceX+190} ${sourceY+0}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle}/>
      <EdgeLabelRenderer>
        <div style={{
            position: 'absolute',
            transform: `translate(-50%, -100%) translate(${labelX}px,${labelY}px)`,
          }}>
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
