import React from 'react';
import { BaseEdge, EdgeProps } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {

  const edgeColor = data && (data.enabled.telemetry ? data.enabled.telemetry.includes(data.name) : false) ? 'green' : 'white';

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }


  let finaledge;
  let finaledge1;

  if (id === 'edge321421' ){
    // console.log(sourceX, sourceY, targetX, targetY)
    // console.log(edgePath);
    sourceY = sourceY - 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${targetX-5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    sourceY = sourceY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${targetX-5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge5' || id === 'edge6'){

    sourceX = sourceX - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX-5},${targetY-200}L${targetX+5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetY = targetY + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX-5},${targetY-200}L${targetX+5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge7' || id === 'edge8'){
    sourceX = sourceX + 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX+5},${targetY-200}L${targetX-5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX+5},${targetY-200}L${targetX-5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else{
    sourceX = sourceX - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }

  return (
    <>
      <BaseEdge path={finaledge} style={edgeStyle}/>
      <BaseEdge path={finaledge1} style={edgeStyle}/>
    </>
  );

}
