import React from 'react'
import {InnerData} from './utils';
import { Handle, Position } from 'reactflow';

interface FuelFilterProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
  }
}

const FuelFilter: React.FC<FuelFilterProp> = ({data}) => {

  return (
    <div>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:50, top:20}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:85, left:85}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:85, right:85}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{opacity:0, left:50, bottom:30}}/>

        <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="88px" height="88px" viewBox="-0.5 -0.5 88 88">
          <defs>
            <pattern patternUnits="userSpaceOnUse" width="15.75" height="15.75" x="0" y="0" patternTransform="rotate(45)" id="mx-pattern-cross-hatch-1-000000-0">
              <rect x="3" y="3" width="15.75" height="15.75" stroke="#FFFFFF" strokeWidth="4" fill="none"/>
            </pattern>
          </defs>
          <g>
            <rect x="3" y="3" width="80" height="80" fill="url(#mx-pattern-cross-hatch-1-000000-0)" stroke="#FFFFFF" strokeWidth="6"  pointerEvents="all"/>
          </g>
        </svg>
        </div>

        <p>{data.name}</p>
    </div>
  );
}

export default FuelFilter;
