import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'text',
      name: 'Simple text option',
      description: 'Description of panel option',
      defaultValue: 'Default value of text input option',
    })
    .addBooleanSwitch({
      path: 'showSeriesCount',
      name: 'Show series counter',
      defaultValue: false,
    })
    .addSliderInput({
      path: 'X',
      name: 'X',
      description: 'Adjust the X position',
      settings: {
        min: 0,
        max: 1000,
        step: 1,
      },
      defaultValue: 0,
    })
    .addSliderInput({
      path: 'Y',
      name: 'Y',
      description: 'Adjust the Y position',
      settings: {
        min: 0,
        max: 1000,
        step: 1,
      },
      defaultValue: 0,

    })
    .addSliderInput({
      path: 'Zoom',
      name: 'Zoom',
      description: 'Adjust the Zoom value',
      settings: {
        min: 0,
        max: 1,
        step: 0.01,
      },
      defaultValue: 0.35,
    })
    .addRadio({
      path: 'seriesCountSize',
      defaultValue: 'sm',
      name: 'Series counter size',
      settings: {
        options: [
          {
            value: 'sm',
            label: 'Small',
          },
          {
            value: 'md',
            label: 'Medium',
          },
          {
            value: 'lg',
            label: 'Large',
          },
        ],
      },
      showIf: (config) => config.showSeriesCount,
    })

});
