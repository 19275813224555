import React, { useState } from 'react'
import {InnerData, TLM, determineColorNew} from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';
import * as fuelScheme from './FuelVScheme'; // Import your styles

interface FuelValveProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
    en: TLM;
    status: TLM;
  }
}

type VisibleTooltips = { [key: string]: boolean };

const FuelValve: React.FC<FuelValveProp> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  return (
    <div className={fuelScheme[data.className as fuelScheme.ClassKey]}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:50, top:20}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:85, left:85}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:85, right:85}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{opacity:0, left:50, bottom:30}}/>


        <div>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="86px" height="106px" viewBox="-0.5 -0.5 86 106">
            <defs/>
            <g>
              <rect x="3" y="23" width="80" height="80" fill="none" stroke="#FFFFFF" strokeWidth="6" pointerEvents="all"/>
              <path d="M 3 103 L 83 23" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              <path d="M 83 103 L 3 23" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              <path d="M 43 3 L 43 23" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              <path d="M 13 3 L 73 3" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              <path d="M 73 3 L 83 13" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              <path d="M 13 3 L 3 13" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>

            </g>
          </svg>  
        </div>

        <div className={scheme.svgValueColCon}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
    </div>
  );
}

export default FuelValve;
