import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, decodeFunc, determineLimit, lookUpName, nameToMne, redisDataType } from './utils';
import CustomEdge from './CustomEdge';
import Panel from 'module/Panel';
import PT from './PT';
import FluidEdge2 from './FluidEdge2';
import PMDTank from './PMDTank';
import { css } from '@emotion/css';
import PropThruster from './PropThruster';
import Heater from './Heater';
import ValueBox from './ValueBox';
import { useTimeRangeContext } from './TimeRangeContext';
import FuelValve from './FuelV';
import FuelFilter from './FuelFilter';
import LatchValve from './LatchValve';
import VModeBox from './VModeBox';
import Text from './Text';
import TempSensor from './TempSensor';


type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: string; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type f unit
  live: string;
  mne: string;
  spacecraft: string;
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are any
};

const nodeTypes = {valuebox:ValueBox,text:Text,tempSensor: TempSensor,heater:Heater,vmodeBox: VModeBox, latchValve:LatchValve,fuelValve: FuelValve,fuelFilter: FuelFilter,propThruster:PropThruster, panel:Panel,pmdTank:PMDTank, pt:PT}
const edgeTypes = {customEdge: CustomEdge, fluidedge2: FluidEdge2}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

const App: React.FC<AppProps> = ({dbData, source, width, height, options, influxData, limitData}) => {

  const processRedisData = useCallback(() => {

    const telemetryDict: Record<string, TelemetryData> = {};

    const valueColumn = dbData.find(column => column.name === "value");
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    
    if (!valueColumn) {
      return {}; // mne column not found
    }

    const valueValue = valueColumn && valueColumn.values ? valueColumn.values : [];

    valueValue.map(value => {
      const decodeValue = decodeFunc(value) as redisDataType

      const mne = decodeValue.mne;
      const limitMneIndex = limitMneColumn && limitMneColumn.values.indexOf(mne);

      
      let limitValue;
      let cnvValue = decodeValue.cnv;

      if (limitMneIndex === -1 || !limitMneIndex) {
        limitValue = ''
      }
      else {

        const ylValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null;
        const yHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null;
        const rlValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null;
        const rHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        limitValue = determineLimit(cnvValue, {
          yl: ylValue,
          yh: yHValue,
          rl: rlValue,
          rh: rHValue
        })
      }

      const valueName = lookUpName(mne);

      // Special handling for 'WFI HV' and 'NFI HV'
      if (mne === 'tcs_spoc_rtd_n2_bottle_probe_1_t' || mne === 'tcs_spoc_rtd_n2_bottle_probe_2_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_3_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_4_t') {
        cnvValue = parseFloat(cnvValue).toExponential(2);
      } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
        // Check if cnvValue is a number and convert/round it if s
        const numValue = parseFloat(cnvValue);
        const [integerPart, decimalPart] = numValue.toString().split('.');

        // Convert to exponential form if the integer part is too long
          if (integerPart.length > 7) {
        cnvValue = numValue.toExponential(2);
          } else if (decimalPart) {
            // It's a float, parse and keep 2 decimal places
            cnvValue = numValue.toFixed(2);
          }
      }

      telemetryDict[valueName] = {
        limit: limitValue,
        mne: mne,
        telemetry: cnvValue,
        live: isWithinDifference(decodeValue.t_insert, 30),
        spacecraft: decodeValue.facility,
        unit: ""
      };
    });

    return telemetryDict;
  },[dbData, limitData]);

  const editRedisDataLimit = useCallback((data: TelemetryDictionary) => {
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    const limitSwitchMneColumn = limitData.find(column => column.name === "switchMne");
    const limitSwitchLowerColumn = limitData.find(column => column.name === "switchLower");
    const limitSwitchUpperColumn = limitData.find(column => column.name === "switchUpper");

    const limitSwitchMneValue = limitSwitchMneColumn && limitSwitchMneColumn.values ? limitSwitchMneColumn.values : [];

    // if there is a switchMne coumn, then we need to use this set of limit. Therefore we will replace the old limit value
    // with the new limit value 
    limitSwitchMneValue.map(switchMneValue => {
      
      const limitMneIndex = limitSwitchMneColumn && limitSwitchMneColumn.values.indexOf(switchMneValue);

      if (limitMneIndex) {
        
        const limitSwitchLowerValue = limitSwitchLowerColumn ? limitSwitchLowerColumn.values[limitMneIndex] : "";
        const limitSwitchUpperValue = limitSwitchUpperColumn ? limitSwitchUpperColumn.values[limitMneIndex] : "";
        const limitMneValue = limitMneColumn ? limitMneColumn.values[limitMneIndex] : null;
        const limitYLValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null
        const limitYHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null
        const limitRLValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null
        const limitRHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        const switchMneName = lookUpName(switchMneValue);
        const mneName = lookUpName(limitMneValue);

        const switchMneCnv = data[switchMneName].telemetry;
        const mneValue = data[mneName].telemetry;

        if (limitSwitchLowerValue <= switchMneCnv <= limitSwitchUpperValue){

          const limit = determineLimit(mneValue, {
            yl: limitYLValue,
            yh: limitYHValue,
            rl: limitRLValue,
            rh: limitRHValue
          });

          data[mneName].limit = limit;

        }

      }

    });

    return data;
  }, [limitData]);
  
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0"); 
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  function isWithinDifference(unixTimestamp: number, differenceInSeconds: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;
    // Compare and return
    
    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns 
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex], 30) : "";

    // Append the degree symbol tothe unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'na' || unit === 'enum'){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'Counts'){
      unit = 'Cnt'
    }else if(unit === 'rad/s'){
      unit = 'r/s'
    }
  
    // Special handling for 'WFI HV' and 'NFI HV'
    if (mne === 'tcs_spoc_rtd_n2_bottle_probe_1_t' || mne === 'tcs_spoc_rtd_n2_bottle_probe_2_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_3_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_4_t') {
      cnvValue = parseFloat(cnvValue).toExponential(2);
    } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
      // Check if cnvValue is a number and convert/round it if s
      const numValue = parseFloat(cnvValue);
      const [integerPart, decimalPart] = numValue.toString().split('.');

      // Convert to exponential form if the integer part is too long
        if (integerPart.length > 7) {
      cnvValue = numValue.toExponential(2);
        } else if (decimalPart) {
          // It's a float, parse and keep 2 decimal places
          cnvValue = numValue.toFixed(2);
        }
    }
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
  
      const data = getDataFromSource(mne.toLowerCase());
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, source]);

  const redisData = processRedisData();
  const redisDataWithLimit = editRedisDataLimit(redisData);

  const telemetryDictionary = redisDataWithLimit;

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'panel', position:{x:50, y:50}, data:{'source':source}},
      
    { id: 'PropThruster1', type: 'propThruster',parentNode:'Panel', position:{x:300, y:1685}, data:{name:'Thruster 1',value:{'T':{'dbData':telemetryDictionary['Thruster T1'],'fname':''}}}},
    { id: 'PropThruster2', type: 'propThruster',parentNode:'Panel', position:{x:700, y:1685}, data:{name:'Thruster 2',value:{'T':{'dbData':telemetryDictionary['Thruster T2'],'fname':''}}}},
    { id: 'PropThruster3', type: 'propThruster',parentNode:'Panel', position:{x:1000, y:1685}, data:{name:'Thruster 3',value:{'T':{'dbData':telemetryDictionary['Thruster T3'],'fname':''}}}},
    { id: 'PropThruster4', type: 'propThruster',parentNode:'Panel', position:{x:1400, y:1685}, data:{name:'Thruster 4',value:{'T':{'dbData':telemetryDictionary['Thruster T4'],'fname':''}}}},

    { id: 'LatchValve8', type: 'latchValve',parentNode:'Panel', position:{x:1485, y:1575}, data:{value:{'T':{'dbData':telemetryDictionary['CatBed T4'],'fname':''}}}},
    { id: 'LatchValve7', type: 'latchValve',parentNode:'Panel', position:{x:1485, y:1475}, data:{value:{'T':{'dbData':telemetryDictionary['Thruster Valve T4'],'fname':''}}}},
    { id: 'LatchValve6', type: 'latchValve',parentNode:'Panel', position:{x:1085, y:1575}, data:{value:{'T':{'dbData':telemetryDictionary['CatBed T3'],'fname':''}}}},
    { id: 'LatchValve5', type: 'latchValve',parentNode:'Panel', position:{x:1085, y:1475}, data:{value:{'T':{'dbData':telemetryDictionary['Thruster Valve T3'],'fname':''}}}},
    { id: 'SValveText', type: 'text',parentNode:'Panel', position:{x:875, y:1525}, data:{input:'Solenoid Valve'}},
    { id: 'LatchValve4', type: 'latchValve',parentNode:'Panel', position:{x:515, y:1575}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['CatBed T20'],'fname':''}}}},
    { id: 'LatchValve3', type: 'latchValve',parentNode:'Panel', position:{x:515, y:1475}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['Thruster Valve T2'],'fname':''}}}},
    { id: 'LatchValve2', type: 'latchValve',parentNode:'Panel', position:{x:115, y:1575}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['CatBed T1'],'fname':''}}}},
    { id: 'LatchValve1', type: 'latchValve',parentNode:'Panel', position:{x:115, y:1475}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['Thruster Valve T1'],'fname':''}}}},

    { id: 'LatchValve', type: 'latchValve',parentNode:'Panel', position:{x:900, y:1100}, data:{name:'Latch Valve',value:{'T':{'dbData':telemetryDictionary['Prop PLV Temp'],'fname':''}}}},

    { id: 'ExtLine3', type: 'tempSensor',parentNode:'Panel', position:{x:1375, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T35 Ext Line'],'fname':''}}}},
    { id: 'ExtLine2', type: 'tempSensor',parentNode:'Panel', position:{x:625, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T34 Ext Line'],'fname':''}}}},
    { id: 'ExtLine1', type: 'tempSensor',parentNode:'Panel', position:{x:275, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T33 Ext Line'],'fname':''}}}},

    { id: 'FuelFilter', type: 'fuelFilter',parentNode:'Panel', position:{x:898, y:875}, data:{name:'Fuel Filter'}},

    { id: 'PT', type: 'pt',parentNode:'Panel', position:{x:1185, y:655}, data:{name:'', value:{'Pwr':{'dbData':telemetryDictionary['PT Pwr'],'fname':''}, 'V':{'dbData':telemetryDictionary['PT Volt'],'fname':''}, 'T':{'dbData':telemetryDictionary['PT Temp'],'fname':''}}}},

    { id: 'FuelValve2', type: 'fuelValve',parentNode:'Panel', position:{x:375, y:675}, data:{name: 'Fuel Valve',className:"FillDrain" , value:{'T':{'dbData':telemetryDictionary['Fill Drain T'],'fname':''}}}},

    { id: 'InLine1', type: 'tempSensor',parentNode:'Panel', position:{x:525, y:575}, data:{value:{'T':{'dbData':telemetryDictionary['T29 Int Line'],'fname':''}}}},

    { id: 'Tank', type: 'pmdTank',parentNode:'Panel', position:{x:625, y:370}, data:{name:'', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}, value1:{'T':{'dbData':telemetryDictionary['GN2 Temp'],'fname':''}}, value2:{'T':{'dbData':telemetryDictionary['N2H4 Temp'],'fname':''}}}},

    { id: 'FuelValve1', type: 'fuelValve',parentNode:'Panel', position:{x:375, y:150}, data:{name: 'Pressurant Valve',className:"FillDrain" , value:{'T':{'dbData':telemetryDictionary['PF Temp'],'fname':''}}}},
    
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:50, y:-50}, data:{name:'', playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

  ]);

  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Panel', type: 'panel', position:{x:50, y:50}, data:{'source':source}},
      
      { id: 'PropThruster1', type: 'propThruster',parentNode:'Panel', position:{x:300, y:1685}, data:{name:'Thruster 1',value:{'T':{'dbData':telemetryDictionary['Thruster T1'],'fname':''}}}},
      { id: 'PropThruster2', type: 'propThruster',parentNode:'Panel', position:{x:700, y:1685}, data:{name:'Thruster 2',value:{'T':{'dbData':telemetryDictionary['Thruster T2'],'fname':''}}}},
      { id: 'PropThruster3', type: 'propThruster',parentNode:'Panel', position:{x:1000, y:1685}, data:{name:'Thruster 3',value:{'T':{'dbData':telemetryDictionary['Thruster T3'],'fname':''}}}},
      { id: 'PropThruster4', type: 'propThruster',parentNode:'Panel', position:{x:1400, y:1685}, data:{name:'Thruster 4',value:{'T':{'dbData':telemetryDictionary['Thruster T4'],'fname':''}}}},

      { id: 'LatchValve8', type: 'latchValve',parentNode:'Panel', position:{x:1485, y:1575}, data:{value:{'T':{'dbData':telemetryDictionary['CatBed T4'],'fname':''}}}},
      { id: 'LatchValve7', type: 'latchValve',parentNode:'Panel', position:{x:1485, y:1475}, data:{value:{'T':{'dbData':telemetryDictionary['Thruster Valve T4'],'fname':''}}}},
      { id: 'LatchValve6', type: 'latchValve',parentNode:'Panel', position:{x:1085, y:1575}, data:{value:{'T':{'dbData':telemetryDictionary['CatBed T3'],'fname':''}}}},
      { id: 'LatchValve5', type: 'latchValve',parentNode:'Panel', position:{x:1085, y:1475}, data:{value:{'T':{'dbData':telemetryDictionary['Thruster Valve T3'],'fname':''}}}},
      { id: 'SValveText', type: 'text',parentNode:'Panel', position:{x:875, y:1525}, data:{input:'Solenoid Valve'}},
      { id: 'LatchValve4', type: 'latchValve',parentNode:'Panel', position:{x:515, y:1575}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['CatBed T20'],'fname':''}}}},
      { id: 'LatchValve3', type: 'latchValve',parentNode:'Panel', position:{x:515, y:1475}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['Thruster Valve T2'],'fname':''}}}},
      { id: 'LatchValve2', type: 'latchValve',parentNode:'Panel', position:{x:115, y:1575}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['CatBed T1'],'fname':''}}}},
      { id: 'LatchValve1', type: 'latchValve',parentNode:'Panel', position:{x:115, y:1475}, data:{className:'leftCon',value:{'T':{'dbData':telemetryDictionary['Thruster Valve T1'],'fname':''}}}},

      { id: 'LatchValve', type: 'latchValve',parentNode:'Panel', position:{x:900, y:1100}, data:{name:'Latch Valve',value:{'T':{'dbData':telemetryDictionary['Prop PLV Temp'],'fname':''}}}},

      { id: 'ExtLine3', type: 'tempSensor',parentNode:'Panel', position:{x:1375, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T35 Ext Line'],'fname':''}}}},
      { id: 'ExtLine2', type: 'tempSensor',parentNode:'Panel', position:{x:625, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T34 Ext Line'],'fname':''}}}},
      { id: 'ExtLine1', type: 'tempSensor',parentNode:'Panel', position:{x:275, y:1250}, data:{value:{'T':{'dbData':telemetryDictionary['T33 Ext Line'],'fname':''}}}},

      { id: 'FuelFilter', type: 'fuelFilter',parentNode:'Panel', position:{x:898, y:875}, data:{name:'Fuel Filter'}},

      { id: 'PT', type: 'pt',parentNode:'Panel', position:{x:1185, y:655}, data:{name:'', value:{'Pwr':{'dbData':telemetryDictionary['PT Pwr'],'fname':''}, 'V':{'dbData':telemetryDictionary['PT Volt'],'fname':''}, 'T':{'dbData':telemetryDictionary['PT Temp'],'fname':''}}}},

      { id: 'FuelValve2', type: 'fuelValve',parentNode:'Panel', position:{x:375, y:675}, data:{name: 'Fuel Valve',className:"FillDrain" , value:{'T':{'dbData':telemetryDictionary['Fill Drain T'],'fname':''}}}},

      { id: 'InLine1', type: 'tempSensor',parentNode:'Panel', position:{x:525, y:575}, data:{value:{'T':{'dbData':telemetryDictionary['T29 Int Line'],'fname':''}}}},

      { id: 'Tank', type: 'pmdTank',parentNode:'Panel', position:{x:625, y:370}, data:{name:'', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}, value1:{'T':{'dbData':telemetryDictionary['GN2 Temp'],'fname':''}}, value2:{'T':{'dbData':telemetryDictionary['N2H4 Temp'],'fname':''}}}},

      { id: 'FuelValve1', type: 'fuelValve',parentNode:'Panel', position:{x:375, y:150}, data:{name: 'Pressurant Valve',className:"FillDrain" , value:{'T':{'dbData':telemetryDictionary['PF Temp'],'fname':''}}}},
      
      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:50, y:-50}, data:{name:'', playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]}, [source]);


    useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) {
        return;
      }
    
      let isCancelled = false; 
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    }, [influxData, guiValues.current.pbisInPlayBack, createNodes, guiValues]);

    useEffect(() => {
      if (guiValues.current.pbisInPlayBack) {
        return;
      }

      const redisData = processRedisData();
      const redisDataWithLimit = editRedisDataLimit(redisData);
      const telemetryDictionary1 = redisDataWithLimit;

      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [dbData, createTelemetryDictionary, createNodes, guiValues, editRedisDataLimit, processRedisData]);


  const initialEdges = [
 
    {id: 'edge1', source: 'FuelValve1',sourceHandle: 'source-right', target: 'Tank',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge2', source: 'Tank',sourceHandle: 'source-bottom', target: 'FuelValve2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge21', source: 'PT',sourceHandle: 'source-left', target: 'FuelValve2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge3', source: 'Tank',sourceHandle: 'source-bottom', target: 'FuelFilter',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge4', source: 'FuelFilter',sourceHandle: 'source-bottom', target: 'LatchValve',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge5', source: 'LatchValve',sourceHandle: 'source-bottom-1', target: 'LatchValve1',targetHandle:'target-top-2', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge6', source: 'LatchValve',sourceHandle: 'source-bottom-1', target: 'LatchValve3',targetHandle:'target-top-2', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge7', source: 'LatchValve',sourceHandle: 'source-bottom-1', target: 'LatchValve5',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge8', source: 'LatchValve',sourceHandle: 'source-bottom-1', target: 'LatchValve7',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},

    {id: 'edge9', source: 'ExtLine3',sourceHandle: 'source-bottom', target: 'LatchValve',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge10', source: 'ExtLine2',sourceHandle: 'source-bottom', target: 'LatchValve',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge11', source: 'ExtLine1',sourceHandle: 'source-bottom', target: 'LatchValve',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge12', source: 'InLine1',sourceHandle: 'source-right', target: 'LatchValve',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},

 ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
            onInit={onInit}
          edges={initialEdges}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.2}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}

          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
