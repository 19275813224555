import { css } from '@emotion/css';
export const smallPt = css` 

    & > div {

        & > div {
            width: 225px;
        }
    }
`;

export const RCST2Container = css`
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: space-between;
width: 350px;
`;

export const RCST1Container = css`
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: space-between;
width: 350px;
`;


// Define a type for the class key
export type ClassKey = 'smallPt' | 'RCST2Container' | 'RCST1Container';