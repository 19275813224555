import { css } from '@emotion/css';
import React from 'react'

type TextProps = {
    data: {
        input: string;
    }
}

const Text: React.FC<TextProps> = ({ data }) => {
    return (
        <div className={css`width:200px`}>
            <p>{data.input}</p>
        </div>
    )
}

export default Text

