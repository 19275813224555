import { decode } from "@msgpack/msgpack";
import copy from 'copy-to-clipboard';

export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possible
};

export interface ProcessedData {
  [key: string]: Array<{ cnvValue: string, mne: string, name: string }>;
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  "LV Close Pwr":"prlvclpw", /* Latch Valve Close Power */
  "LV Open Pwr":"prlvoppw", /* Latch Valve Open Power */
  "LV Pos":"prlvpos", /* Latch Valve Position */

  "PT Volt":"prpressv", /* Pressure Transducer Voltage */
  "PT Pwr":"pwprpresspwr", /* PROP Pressure Transducer Power */
  "PT Temp":"t40propptt", /* PROP Pressure Transducer Spacecraft Temp */

  "Prop N2H4 Temp":"t36tankn2h4t", /* Tank N2H4 Spacecraft Temp */
  "Fill Drain T":"t42filldrainvalven2h4t", /* Temp, Prop Fill/Drain Line N2H4 */

  "Prop GN2 Temp":"t37tankgn2t", /* PROP Tank GN2 Spacecraft Temp */

  "Prop PLV Temp":"t38propplvt", /* PROP PLV Spacecraft Temp */

  "PF Temp":"t39proppft", /* PROP PF Spacecraft Temp */

  "CatBed T3":"t43thrustercatbed3t", /* Temp, Catalyst Bed 3 */ 
  "CatBed T2":"t44thrustercatbed2t", /* Temp, Catalyst Bed 2 */
  "CatBed T4":"t45thrustercatbed4t", /* Temp, Catalyst Bed 4 */
  
  "CatBed T1":"t46thrustercatbed1t", /* Temp, Catalyst Bed 1 */

  "Thruster Valve T3":"t47thrustervalve3t", /* PROP DVT 3 Valve Spacecraft Temp */
  "Thruster Valve T2":"t48thrustervalve2t", /* PROP DVT 2 Valve Spacecraft Temp */
  "Thruster Valve T4":"t49thrustervalve4t", /* PROP DVT 4 Valve Spacecraft Temp */
  "Thruster Valve T1":"t50thrustervalve41", /* PROP DVT 1 Valve Spacecraft Temp */
  
  "Thruster T1":"adt1firet", /* Thruster 1 Fire */
  "Thruster T2":"adt2firet", /* Thruster 2 Fire */
  "Thruster T3":"adt3firet", /* Thruster 3 Fire */
  "Thruster T4":"adt4firet", /* Thruster 4 Fire */

  "GN2 Temp": "t37tankgn2t", /* PROP Tank GN2 Spacecraft Temp */
  "N2H4 Temp": "t36tankn2h4t", /* PROP Tank N2h4 Spacecraft Temp */

  "T33 Ext Line": "t33extlinesmyt", /* Temp, Thruster Prop Line 2 External */
  "T34 Ext Line": "t34extlinespyt", /* Temp, Thruster Prop Line 1 External */
  "T35 Ext Line": "t35extlinespxt", /* External Lines PX Spacecraft Temp */
  "T29 Int Line": "t29intlines1t", /* Temp, Thruster Prop Line 1 Internal */
  "T30 Int Line": "t30intlines2t", /* Temp, Thruster Prop Line 2 Internal */
  "T31 Int Line": "t31intlines3t", /* Temp, Thruster Prop Line 3 Internal */



}

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}

export function determineColorNew(name: string){

if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}

export const decodeFunc = (data: string) => {
  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

export type redisDataType = {
  apid: number;
  cnv: string;
  facility: string;
  is_pseudo: boolean;
  mne: string;
  raw: number;
  scid: number;
  t_insert: number;
  t_pkt: number;
  vcid: number;
};


export type VisibleTooltips = { [key: string]: boolean };

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};
