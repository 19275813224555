import * as scheme from './Scheme'; // Import yor styles
import React, { useState } from "react";
import { InnerData, TLM, determineColorNew} from "./utils";
import { Handle, Position } from "reactflow";
import PlotlyChart from "./PlotlyChart";
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

interface HeaterProp {
  data: {
    name: string;
    value: InnerData;
    classname: string;
    en: TLM
  };
}

const Heater: React.FC<HeaterProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const heaterContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `

  const getColor = (value: TLM) => {
    if (value && value.telemetry === "On"){
      return "green"
    }
    else{
      return "rgb(100,100,100)"
    }
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ top: 20, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 20,opacity:0 }}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:20}}/>
      <Handle type="source" position={Position.Right} id="source-right-2" style={{opacity:0, top:20}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={heaterContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="162px" height="42px" viewBox="-0.5 -0.5 162 42">
            <defs />
            <g>
            <rect x="40" y="0" width="40" height="40" fill="rgb(38,38,38)" stroke={getColor(data.en)} strokeWidth={6} pointerEvents="all" />
            <rect x="80" y="0" width="40" height="40" fill="rgb(38,38,38)" stroke={getColor(data.en)} strokeWidth={6} pointerEvents="all" />
              <path d="M 120 19.76 L 160 20" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 0 19.66 L 40 19.9" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
            </g>
          </svg>

          <span>{data.name}</span>

          <div className={scheme.svgValueColCon}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}</div>

      </div>
    </div>
  );
};

export default Heater;
